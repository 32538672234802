import { ISalarySlip } from '@/extension-portal/types/salaryEntity/entities'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ISalaryList {
  isLoading: boolean
  salaryList: ISalarySlip[]
  page: number
  limit: number
  totalPage: number
  totalUsers: number
}

export const initialState: ISalaryList = {
  isLoading: false,
  salaryList: [],
  page: 1,
  limit: 10,
  totalPage: 0,
  totalUsers: 0,
}

export const salarySlipReducer = createSlice({
  name: 'salarySlip',
  initialState,
  reducers: {
    setIsLoading: (state: ISalaryList, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setSalarySlip: (state: ISalaryList, action: PayloadAction<any[]>) => {
      state.salaryList = action.payload
    },
    setPage: (state: ISalaryList, action: PayloadAction<number>) => {
      state.page = action.payload
    },
    setLimit: (state: ISalaryList, action: PayloadAction<number>) => {
      state.limit = action.payload
    },
    setTotalPage: (state: ISalaryList, action: PayloadAction<number>) => {
      state.totalPage = action.payload
    },

    setTotalUsers: (state: ISalaryList, action: PayloadAction<number>) => {
      state.totalUsers = action.payload
    },
  },
})

export const { setIsLoading, setPage, setLimit, setTotalPage, setSalarySlip, setTotalUsers } =
  salarySlipReducer.actions
export default salarySlipReducer.reducer
